import React from "react"
import { gsap, Draggable, Sine } from "gsap/all";


//const Tree = ({ num, file }) => {
class Badge extends React.Component {

    componentDidMount = () => {
    
        //Register draggable plugin
        gsap.registerPlugin( Draggable ); 
        
        //drag ref
        let drag = this.refs.badgeRef;

        //animate in from offscreen
        let angle = Math.random() * 360;
	    let tx = Math.sin(angle) * 2000;
	    let ty = Math.cos(angle) * 2000;
                
        //gsap.set(drag, { rotation:Math.random() * 380, x:tx, y:ty } );
		gsap.from(drag, Math.random() * 1 + 1, { rotation:Math.random() * 380, x:tx, y:ty, ease:Sine.easeOut, delay: 0.2 } );
        
        Draggable.create(drag, {
            type: 'x,y',
            onPress: function (e) {
                gsap.to(drag, 0.1, {rotation: Math.random() * 20 -10});
            },
            onDrag: function () {
                
            },
            onRelease: function (e) {
                e.preventDefault();
                gsap.to(drag, 0.2, {scaleX:1, scaleY:1});
                
            }
        });
    }

    onFocus = () => {

    }
    onBlur = () => {

    }
    onMouseOver = () => {
        gsap.to(this.refs.badgeRef, 0.1, {scaleX:1.2, scaleY:1.2});
    }
    onMouseOut = () => {
        gsap.to(this.refs.badgeRef, 0.1, {scaleX:1, scaleY:1});
    }

    render(){
        return (
            <button className="badgeContainer" style={this.props.style} ref="badgeRef" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onFocus={this.onFocus} onBlur={this.onBlur}>
                <input type="image" className="badge" src={this.props.src} alt={this.props.alt} />
            </button>
        );
    }
}

export default Badge
