/** Ends and Odds: Badges **/

import React from "react";
import { gsap, Draggable } from "gsap/all";
import { preloadImages, scaleFullscreen } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import Badge from "../../components/ends-and-odds/badge";


let globalState, data, preloadData;

class EndsAndOddsPage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];

    //refs
    this.fullscreenBg = null;

  }

  state = {
    textVisible: false,
    badgesVisible: false
  }




  componentDidMount = () => {


    setTimeout(() => {
      this.setState({badgesVisible: true});
    }, globalState.transitionDuration);

    //Register draggable plugin
    gsap.registerPlugin(Draggable);

    //show text
    gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });

    //update base track volume
    globalState.baseAudioRef.updateVolume(1, 100);
    

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    //Update dimensions
    this.updateDimensions();



    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.bgVideoPoster], 
      this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {

    //Kill any tweens
    //gsap.killTweensOf(this.refs.text1Ref);

    window.removeEventListener("resize", this.updateDimensions);

    
    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);
  }


  updateDimensions = () => {
    console.log('updateDimensions');
    //background native size is 1288 x 779
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 779, false);
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/ends-and-odds/4" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/ends-and-odds/4" prevLocation="/ends-and-odds/2" />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Ends and Odds" />

          <div className="text-wrapper">
            <div className="text text--center-center" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} alt="Some objects hold the welled-up history that made them possible; people cared enough to honour a point in time, name a group, have a badge made." style={{ pointerEvents: 'none', marginTop: '-22px' }} />
            </div>
          </div>


          {this.state.badgesVisible ?
          <div className="badge-wrapper">
          
            <Badge src={globalState.cdnUrl + data.badge25} style={{ left: '-524px', top: '109px', transform: 'rotate(5deg)' }} alt="Fat Albert"></Badge>
            <Badge src={globalState.cdnUrl + data.badge27} style={{ left: '-224px', top: '124px', transform: 'rotate(3deg)' }} alt="Fire Department"></Badge>
            <Badge src={globalState.cdnUrl + data.badge26} style={{ left: '-375px', top: '125px', transform: 'rotate(8deg)' }} alt="Mosquito Trap"></Badge>
            <Badge src={globalState.cdnUrl + data.badge28} style={{ left: '-103px', top: '129px', transform: 'rotate(-7deg)' }} alt="Closure Crest"></Badge>
            <Badge src={globalState.cdnUrl + data.badge29} style={{ left: '76px', top: '178px', transform: 'rotate(0deg)' }} alt="RCMP"></Badge>
            <Badge src={globalState.cdnUrl + data.badge31} style={{ left: '321px', top: '195px', transform: 'rotate(-4deg)' }} alt="75 Years"></Badge>
            <Badge src={globalState.cdnUrl + data.badge30} style={{ left: '214px', top: '160px', transform: 'rotate(-4deg)' }} alt="Matonabbee School"></Badge>
            <Badge src={globalState.cdnUrl + data.badge32} style={{ left: '405px', top: '105px', transform: 'rotate(-4deg)' }} alt="Pine Point Buckle"></Badge>
            <Badge src={globalState.cdnUrl + data.badge33} style={{ left: '566px', top: '162px', transform: 'rotate(0deg)' }} alt="Fire Chief"></Badge>

            <Badge src={globalState.cdnUrl + data.badge22} style={{ left: '269px', top: '-15px', transform: 'rotate(-6deg)' }} alt="George"></Badge>
            <Badge src={globalState.cdnUrl + data.badge23} style={{ left: '417px', top: '2px', transform: 'rotate(-2deg)' }} alt="Pine Point Taxi"></Badge>
            <Badge src={globalState.cdnUrl + data.badge24} style={{ left: '574px', top: '43px', transform: 'rotate(6deg)' }} alt="Pillowcase"></Badge>

            <Badge src={globalState.cdnUrl + data.badge10} style={{ left: '-493px', top: '-108px', transform: 'rotate(9deg)' }} alt="Pine Point Mines Coin"></Badge>
            <Badge src={globalState.cdnUrl + data.badge11} style={{ left: '-339px', top: '-114px', transform: 'rotate(0deg)' }} alt="Five Star Water &amp; Gravel Hauling"></Badge>
            <Badge src={globalState.cdnUrl + data.badge12} style={{ left: '-199px', top: '-108px', transform: 'rotate(0deg)' }} alt="Mosquito Country"></Badge>
            <Badge src={globalState.cdnUrl + data.badge13} style={{ left: '-77px', top: '-128px', transform: 'rotate(0deg)' }} alt="First Pin"></Badge>
            <Badge src={globalState.cdnUrl + data.badge14} style={{ left: '104px', top: '-132px', transform: 'rotate(-2deg)' }} alt="Fun under the midnight sun"></Badge>
            <Badge src={globalState.cdnUrl + data.badge15} style={{ left: '251px', top: '-128px', transform: 'rotate(-9deg)' }} alt="Matonabbee School"></Badge>

            <Badge src={globalState.cdnUrl + data.badge6} style={{ left: '179px', top: '-300px', transform: 'rotate(-6deg)' }} alt="Pine Point Matonabbee School"></Badge>
            <Badge src={globalState.cdnUrl + data.badge9} style={{ left: '590px', top: '-257px', transform: 'rotate(0deg)' }} alt="Water Tower"></Badge>
            <Badge src={globalState.cdnUrl + data.badge8} style={{ left: '473px', top: '-258px', transform: 'rotate(-9deg)' }} alt="Pine Point N.W.T"></Badge>
            <Badge src={globalState.cdnUrl + data.badge7} style={{ left: '329px', top: '-259px', transform: 'rotate(9deg)' }} alt="Pine Point Scouts"></Badge>
            <Badge src={globalState.cdnUrl + data.badge5} style={{ left: '55px', top: '-256px', transform: 'rotate(-3deg)' }} alt="Polar Bear School"></Badge>
            <Badge src={globalState.cdnUrl + data.badge4} style={{ left: '-105px', top: '-276px', transform: 'rotate(4deg)' }} alt="Arctic Games"></Badge>
            <Badge src={globalState.cdnUrl + data.badge3} style={{ left: '-238px', top: '-262px', transform: 'rotate(-5deg)' }} alt="Polar Bear"></Badge>
            <Badge src={globalState.cdnUrl + data.badge2} style={{ left: '-379px', top: '-254px', transform: 'rotate(0deg)' }} alt="Figure Skating"></Badge>
            <Badge src={globalState.cdnUrl + data.badge1} style={{ left: '-537px', top: '-297px', transform: 'rotate(-5deg)' }} alt="Elementary Pin"></Badge>

            <Badge src={globalState.cdnUrl + data.badge16} style={{ left: '399px', top: '-136px', transform: 'rotate(1deg)' }} alt="Hockey Pin"></Badge>
            <Badge src={globalState.cdnUrl + data.badge17} style={{ left: '562px', top: '-144px', transform: 'rotate(-6deg)' }} alt="Pine Point Scouts Patch"></Badge>

            <Badge src={globalState.cdnUrl + data.badge18} style={{ left: '-469px', top: '10px', transform: 'rotate(0deg)' }} alt="Turned out the lights"></Badge>
            <Badge src={globalState.cdnUrl + data.badge19} style={{ left: '-359px', top: '5px', transform: 'rotate(0deg)' }} alt="Pine Point Curling Club"></Badge>
            <Badge src={globalState.cdnUrl + data.badge20} style={{ left: '-161px', top: '15px', transform: 'rotate(1deg)' }} alt="By-Law Enforcement Dept."></Badge>
            <Badge src={globalState.cdnUrl + data.badge21} style={{ left: '106px', top: '-10px', transform: 'rotate(0deg)' }} alt="Town Pin"></Badge>

            <Badge src={globalState.cdnUrl + data.badge34} style={{ left: '-513px', top: '305px', transform: 'rotate(-4deg)' }} alt="N 81 Mines"></Badge>
            <Badge src={globalState.cdnUrl + data.badge35} style={{ left: '-380px', top: '250px', transform: 'rotate(0deg)' }} alt="Galena Heights"></Badge>
            <Badge src={globalState.cdnUrl + data.badge36} style={{ left: '-186px', top: '277px', transform: 'rotate(4deg)' }} alt="Metal Ball"></Badge>
            <Badge src={globalState.cdnUrl + data.badge37} style={{ left: '-15px', top: '263px', transform: 'rotate(0deg)' }} alt="Basketball Crest"></Badge>
            <Badge src={globalState.cdnUrl + data.badge38} style={{ left: '118px', top: '275px', transform: 'rotate(6deg)' }} alt="Fire Department"></Badge>
            <Badge src={globalState.cdnUrl + data.badge39} style={{ left: '276px', top: '329px', transform: 'rotate(-7deg)' }} alt="Army Cadet Badge"></Badge>
            <Badge src={globalState.cdnUrl + data.badge40} style={{ left: '426px', top: '282px', transform: 'rotate(0deg)' }} alt="Rescue Team"></Badge>
            <Badge src={globalState.cdnUrl + data.badge41} style={{ left: '-189px', top: '405px', transform: 'rotate(-5deg)' }} alt="Lioness Pin"></Badge> 
          </div>
          : "" }

          <div className="fullscreen-bg" ref="fullscreenBgRef">

            <img src={globalState.cdnUrl + data.background} alt="background" className="background table-bg" />
          </div>


        </div>
        )}
      </>
    );
  }


};

export default EndsAndOddsPage3
